import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PayeeType } from "api/payment/types/payee-type";
import { PaymentConfiguration } from "api/payment/types/payment-configuration";
import {
  PayeeSearchParams,
  UpdatePaymentSystemConfiguration,
} from "api/payment/types/payment-configuration-queries";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

const CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION = "PaymentSystemConfiguration";

export const paymentSytemConfigurationApi = createApi({
  reducerPath: "paymentSytemConfigurationApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  tagTypes: [CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION],

  endpoints: (builder) => ({
    getPaymentSystemConfiguration: builder.query<
      PaymentConfiguration,
      PayeeSearchParams
    >({
      query: ({ payeeRef, payeeType }) =>
        `/admin/payment-contexts/payment-system-configuration?payeeRef=${payeeRef}&payeeType=${payeeType}`,
      providesTags: (_result, _error, { payeeRef }) => [
        { type: CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION, id: payeeRef },
      ],
    }),

    upsertPaymentSystemConfiguration: builder.mutation<
      PaymentConfiguration,
      UpdatePaymentSystemConfiguration
    >({
      query: ({ payeeRef, payeeType, configuration }) => ({
        method: "POST",
        url: `/admin/payment-contexts/payment-system-configuration?payeeRef=${payeeRef}&payeeType=${payeeType}`,
        body: configuration,
      }),
      invalidatesTags: [CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION],
    }),

    deletePaymentSystemConfiguration: builder.mutation<
      void,
      {
        payeeRef: number;
        payeeType: PayeeType;
      }
    >({
      query: ({ payeeRef, payeeType }) => ({
        method: "DELETE",
        url: `/admin/payment-contexts?payeeRef=${payeeRef}&payeeType=${payeeType}`,
      }),
      invalidatesTags: [CACHE_KEY_PAYMENT_SYSTEM_CONFIGURATION],
    }),
  }),
});

export const {
  useGetPaymentSystemConfigurationQuery,
  useUpsertPaymentSystemConfigurationMutation,
  useDeletePaymentSystemConfigurationMutation,
} = paymentSytemConfigurationApi;
